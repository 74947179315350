const filterProductsByAnswers = (products, answers) => {
    let results = [...products.edges];

    answers.forEach(({ excludeMatches, relatedProducts }) => {
        if (relatedProducts !== null) {
            results = results.filter(({ node: { tags } }) => {
                const test =
                    typeof tags.find(tag => tag.id === relatedProducts.id) ===
                    'undefined';

                return excludeMatches ? test : !test;
            });
        }
    });

    return results.map(({ node }) => node);
};

export { filterProductsByAnswers };
