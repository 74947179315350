import React from 'react';

const defaultState = {
    isSidebarOpen: false,
    toggleSidebar: () => {}
};

const ThemeContext = React.createContext(defaultState);

class ThemeProvider extends React.Component {
    state = {
        isSidebarOpen: false,
        isContactOpen: false
    };

    toggleSidebar = () => {
        let isSidebarOpen = !this.state.isSidebarOpen;
        localStorage.setItem(
            'Theme.isSidebarOpen',
            JSON.stringify(isSidebarOpen)
        );

        this.toggleBodyScroll(isSidebarOpen);

        this.setState({ isSidebarOpen });
    };

    toggleContactForm = () => {
        let isContactOpen = !this.state.isContactOpen;
        localStorage.setItem(
            'Theme.isContactOpen',
            JSON.stringify(isContactOpen)
        );
        this.setState({ isContactOpen });
    };

    closeSidebar = () => {
        localStorage.setItem('Theme.isSidebarOpen', JSON.stringify(false));
        this.toggleBodyScroll(false);
        this.setState({ isSidebarOpen: false });
    };

    openSidebar = () => {
        localStorage.setItem('Theme.isSidebarOpen', JSON.stringify(true));
        this.toggleBodyScroll(true);
        this.setState({ isSidebarOpen: true });
    };

    toggleBodyScroll = enable => {
        if (enable) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
            document.documentElement.style.overflow = '';
            document.body.style.overflowX = 'hidden';
            document.documentElement.style.overflowX = 'hidden';
        }
    };

    componentDidMount() {
        const lsisSidebarOpen = JSON.parse(
            localStorage.getItem('Theme.isSidebarOpen')
        );
        const lsisContactOpen = JSON.parse(
            localStorage.getItem('Theme.isContactOpen')
        );

        this.toggleBodyScroll(lsisSidebarOpen);

        if (lsisSidebarOpen) {
            this.setState({ isSidebarOpen: lsisSidebarOpen });
        }

        if (lsisContactOpen) {
            this.setState({ isContactOpen: lsisContactOpen });
        }
    }

    render() {
        const { children } = this.props;
        const { isSidebarOpen, isContactOpen } = this.state;
        return (
            <ThemeContext.Provider
                value={{
                    isSidebarOpen,
                    isContactOpen,
                    toggleContactForm: this.toggleContactForm,
                    toggleSidebar: this.toggleSidebar,
                    openSidebar: this.openSidebar,
                    closeSidebar: this.closeSidebar
                }}
            >
                {children}
            </ThemeContext.Provider>
        );
    }
}
export default ThemeContext;

export { ThemeProvider };
