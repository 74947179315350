exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-showrooms-js": () => import("./../../../src/pages/showrooms.js" /* webpackChunkName: "component---src-pages-showrooms-js" */),
  "component---src-pages-spa-chooser-js": () => import("./../../../src/pages/spa-chooser.js" /* webpackChunkName: "component---src-pages-spa-chooser-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-collection-js": () => import("./../../../src/templates/product-collection.js" /* webpackChunkName: "component---src-templates-product-collection-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-landing-js": () => import("./../../../src/templates/product-landing.js" /* webpackChunkName: "component---src-templates-product-landing-js" */)
}

