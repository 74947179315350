import React from 'react';
import { filterProductsByAnswers } from '../helpers/chooserHelpers';
import { navigate } from 'gatsby';

const defaultState = {
    isOpen: false,
    toggle: () => {},
    answers: [],
    currentIndex: 0,
    isComplete: false,
    updateChooser: () => {},
    updateRecommendations: () => {},
    recommendations: []
};

const getLocalStorage = namespace =>
    JSON.parse(localStorage.getItem(namespace));

const ChooserContext = React.createContext(defaultState);

class ChooserProvider extends React.Component {
    state = {
        isOpen: false,
        answers: [],
        currentIndex: 0,
        isComplete: false,
        recommendations: []
    };

    updateChooser = (answer, isComplete, products) => {
        const { currentIndex, answers } = this.state;
        const newAnswers = [...answers, answer];
        const newIndex = currentIndex + 1;
        const newRecommendations = isComplete
            ? filterProductsByAnswers(products, newAnswers)
            : [];

        localStorage.setItem('Chooser.answers', JSON.stringify(newAnswers));

        if (isComplete) {
            localStorage.setItem(
                'Chooser.reccomendations',
                JSON.stringify(newRecommendations)
            );
        }

        if (window && window.dataLayer) {
            window.dataLayer.push({
                event: 'chooser-click',
                'gtm.elementTarget': answer.displayText,
                excludeMatches: answer.excludeMatches,
                relatedTag: answer.relatedProducts
                    ? answer.relatedProducts.slug
                    : false
            });

            window.dataLayer.push({
                event: 'chooser-step',
                stepNumber: !isComplete ? `${newIndex + 1}` : 'complete'
            });
        }

        if (isComplete && newRecommendations.length === 1) {
            navigate(newRecommendations[0].path, {});
        }

        this.setState({
            currentIndex: newIndex,
            isComplete,
            answers: newAnswers,
            recommendations: newRecommendations
        });
    };

    updateRecommendations = (recommendations = []) => {
        this.setState({
            recommendations
        });
    };

    clearChooser = () => {
        [
            'Chooser.answers',
            'Chooser.reccomendations',
            'Chooser.isOpen'
        ].forEach(namespace => localStorage.removeItem(namespace));

        this.setState({
            answers: [],
            currentIndex: 0,
            isComplete: false,
            recommendations: []
        });
    };

    toggle = () => {
        let isOpen = !this.state.isOpen;
        localStorage.setItem('Chooser.isOpen', JSON.stringify(isOpen));
        this.setState({ isOpen });
    };

    componentDidMount() {
        const lsAnswers = getLocalStorage('Chooser.answers');
        const lsRecommendation = getLocalStorage('Chooser.reccomendations');
        const lsIsOpen = getLocalStorage('Chooser.isOpen');

        if (lsIsOpen) {
            this.setState({
                answers: lsAnswers,
                recommendations: lsRecommendation,
                isOpen: lsIsOpen,
                isComplete: true
            });
        } else if (lsAnswers || lsRecommendation) {
            this.setState({
                answers: lsAnswers,
                recommendations: lsRecommendation,
                isComplete: true
            });
        }
    }

    render() {
        const { children } = this.props;
        const {
            isOpen,
            answers,
            currentIndex,
            isComplete,
            recommendations
        } = this.state;

        return (
            <ChooserContext.Provider
                value={{
                    toggle: this.toggle,
                    updateChooser: this.updateChooser,
                    clearChooser: this.clearChooser,
                    updateRecommendations: this.updateRecommendations,
                    isOpen,
                    answers,
                    currentIndex,
                    isComplete,
                    recommendations
                }}
            >
                {children}
            </ChooserContext.Provider>
        );
    }
}
export default ChooserContext;

export { ChooserProvider };
